import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import wallpaper from '../assets/wallpaper.png';
import { IoMdArrowRoundBack } from "react-icons/io";
import { RequestPaiment } from '../services/paiment';
import { PdfIcon, Walletcon } from '../assets/svg';
import { GetDevisById, DownloadPdf } from '../services/devis';
import { DevisItem } from '../services/devis';
import { SkeletonLoader } from '../helpers/loader';

const Devis = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [devisData, setDevisData] = useState<DevisItem | null>(null);
  const [loading, setLoading] = useState(true);
  const [loadingId, setLoadingId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchDevis = async () => {
      if (!id) {
        console.error('No id provided');
        return;
      }

      try {
        const devis = await GetDevisById({ num: id });
        setDevisData(devis);
      } catch (error) {
        console.error('Error fetching devis:', error);
        setError('Failed to fetch devis. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchDevis();
  }, [id]);

  const handleDownload = async (devisId: string) => {
    setLoadingId(devisId);
    setError(null);

    try {
      const pdfBlob = await DownloadPdf({ id: devisId });
      const url = window.URL.createObjectURL(new Blob([pdfBlob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `devis_${devisId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (error) {
      console.error('Error downloading PDF:', error);
      setError('Failed to download PDF. Please try again.');
    } finally {
      setLoadingId(null);
    }
  };
  const handlePayment = async () => {
    if (!devisData?.id) return;
  
    try {

      const response = await RequestPaiment(devisData.id);
  
      if (typeof response === "string" && response.includes("Devi déjà payé")) {
        setError("This invoice has already been paid.");
        return;
      }
  
      if (response) {
        window.open(response, "_blank"); 
      } else {
        throw new Error("Invalid payment response");
      }

    } catch (error) {
      console.error("Error initiating payment:", error);
      setError("Failed to initiate payment. Please try again.");
    }
  };
  

  if (loading) {
    return (
      <div
        className="min-h-screen flex items-center justify-center flex-col gap-5 bg-cover bg-center py-5"
        style={{ backgroundImage: `url(${wallpaper})` }}
      >
        <img src={logo} alt="MyApp Logo" className="h-[5rem]" />
        <SkeletonLoader />
      </div>
    );
  }

  if (!devisData) {
    return (
      <div
        className="min-h-screen flex items-center justify-center flex-col gap-5 bg-cover bg-center py-5"
        style={{ backgroundImage: `url(${wallpaper})` }}
      >
        <img src={logo} alt="MyApp Logo" className="h-[5rem]" />
        <p className="text-center text-gray-500">Aucun devis disponible.</p>
      </div>
    );
  }

  return (
    <div
      className="min-h-screen flex items-center justify-center flex-col gap-5 bg-cover bg-center py-5"
      style={{ backgroundImage: `url(${wallpaper})` }}
    >
      <img src={logo} alt="MyApp Logo" className="h-[5rem]" />
      <div className="m-1 p-2 rounded-lg gap-5 bg-black bg-opacity-10 backdrop-blur-md md:p-8 md:m-9">
        <div className="p-8 rounded-lg bg-white w-full">
          <button onClick={() => navigate(-1)}><IoMdArrowRoundBack size={26} /></button>
          <h1 className="text-4xl font-bold text-center">Avis de règlement</h1>
          <p className="mt-4 text-center text-base text-gray-600">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
          <div className="my-9">
            <div className="border-dashed border-t-2 border-gray-300 mb-4"></div>

            <div className="hidden md:grid md:grid-cols-5 text-left p-4 rounded-t-lg border-b-2 border-gray-300">
              <div className="font-bold text-[#D5AD5D]">Numéro de l’avis</div>
              <div className="font-bold text-[#D5AD5D]">Date</div>
              <div className="font-bold text-[#D5AD5D]">Type d'assurance</div>
              <div className="font-bold text-[#D5AD5D]">Montant de la prime</div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-5 text-left bg-white p-4 mb-4 gap-2 md:gap-0">
              <div className="md:hidden font-bold text-[#D5AD5D]">Numéro de l’avis:</div>
              <div className="md:col-span-1 w-full">{devisData.num}</div>

              <div className="md:hidden font-bold text-[#D5AD5D]">Date:</div>
              <div className="md:col-span-1 w-full">{devisData.created_at.split('T')[0]}</div>

              <div className="md:hidden font-bold text-[#D5AD5D]">Type d'assurance:</div>
              <div className="md:col-span-1 w-full">{devisData.type}</div>

              <div className="md:hidden font-bold text-[#D5AD5D]">Montant de la prime:</div>
              <div className="md:col-span-1 w-full">{devisData.amount}</div>

              <div className="flex justify-center gap-2 mt-2 md:mt-0 md:col-span-1">
                <button
                  className="gap-2 flex items-center bg-[#FDF1DC] text-[#D5AD5D] shadow p-2 rounded transition hover:bg-opacity-70"
                  onClick={() => handleDownload(devisData.num)}
                  disabled={loadingId === devisData.num}
                >
                  PDF <PdfIcon />
                </button>
              </div>
            </div>

            <div className="border-dashed border-t-2 border-gray-300 mt-4"></div>
            <div className="w-full flex justify-between items-center py-4 px-4">
              <span className="font-bold text-gray-800">Total à payer</span>
              <span className="font-bold text-gray-800">{devisData.amount} TND</span>
            </div>
          </div>

          <button
            onClick={handlePayment}
            className="gap-2 flex items-center justify-center bg-[#D5AD5D] text-[#003D5E] shadow p-2 my-5 rounded transition hover:bg-opacity-80 mx-auto w-full md:w-1/2"
          >
            Payer <Walletcon />
          </button>
          {error && <p className="text-red-500 text-center">{error}</p>}

        </div>
      </div>
    </div>
  );
};

export default Devis;
